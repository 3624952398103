<template>
  <div>
  <van-row type="flex">
<!--    顶部按钮-->

    <van-col span="24" v-if="topAppAlertShow">
      <van-sticky>
        <div class="top-app-alert">
          <div class="left">
            <div class="close" @click="topAppAlertShow = false">
              <van-image :src="require('@/assets/images/product/close.png')" width="9" height="9"></van-image>
            </div>
            <van-image :src="require('@/assets/images/product/logo-sm.png')" width="30" height="30"></van-image>
            <div class="text">打开众惠APP，购物更省钱</div>
          </div>
          <div class="open-btn" @click="openAppHandle">立即打开</div>
        </div>
      </van-sticky>
    </van-col>

<!--    商品详情部分-->
<!--    首图-->
    <van-col span="24">
      <div class="detail">
        <div class="banner">
          <template v-if="productDetail.albumPics && productDetail.albumPics.length>0">
            <van-swipe @change="swipeChangeHandle">
              <van-swipe-item v-for="(item, index) in productDetail.albumPics" :key="index">
                <van-image :src="item" width="100%" lazy-load/>
              </van-swipe-item>

              <template #indicator>
                <div class="custom-indicator">{{ swipeCurrent + 1 }}/{{ productDetail.albumPics.length }}</div>
              </template>
            </van-swipe>
          </template>
          <template v-else>
            <van-image :src="productDetail.pic" fit="contain" lazy-load/>


          </template>

        </div>
        <div class="price-wrap">
          <van-image :src="productDetail.priceBgUrl" fit="contain"/>
          <div class="price-absolute">
            <div class="inner">
              <div class="wrap">
                <div class="price-line">
                  <div class="price">
                    <div class="small">￥</div>
                    <template v-if="productDetail.price === 0">0.00</template>
                    <template v-else>
                      {{ formatPrice(productDetail.price)[0] }}<div class="small">{{ '.' + formatPrice(productDetail.price)[1] }}</div>
                    </template>
                  </div>
                  <div class="original-price">
                    <div class="original-price-del-line"></div>
                    <div class="small">￥</div>
                    <template v-if="productDetail.originalPrice === 0">0</template>
                    <template v-else>
                      {{ formatPrice(productDetail.originalPrice)[0] }}<div class="small">{{ '.' + formatPrice(productDetail.originalPrice)[1] }}</div>
                    </template>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
        <div class="product-title">
          <van-icon :name="productDetail.iconUrl" size="16" v-if="productDetail.iconUrl"/>
          {{productDetail.name}}
        </div>
        <div class="block-item service">
          <div class="title">
            官方品质保障
          </div>
          <div class="icon-line-wrap mt-5">
            <van-image :src="require('@/assets/images/product/service-left.png')" width="26" height="26" />
            <van-image :src="require('@/assets/images/product/service-line.png')" width="70"/>
            <van-image :src="require('@/assets/images/product/service-center.png')" width="26" height="26" />
            <van-image :src="require('@/assets/images/product/service-line.png')" width="70"/>
            <van-image :src="require('@/assets/images/product/service-right.png')" width="26" height="26" />
          </div>
          <div class="text-line-wrap">
            <div class="text-item">正品货源</div>
            <div class="text-item">众惠甄选</div>
            <div class="text-item">售后管家</div>
          </div>
        </div>

        <div class="block-item product-chose py-3">
          <van-cell center>
            <template #title>
              <div class="title">
                <div class="chose-title">
                  已选择
                </div>
                <div class="chose-content">
                  {{showSku}}
                </div>
              </div>
            </template>

          </van-cell>
<!--          配送区域-->
<!--          <van-cell>
            <template #title>
              <div class="title">
                <div class="chose-title">
                  配送至
                </div>
                <div class="chose-content">
                </div>
              </div>
            </template>
            <template #right-icon>
              <van-icon :name="require('@/assets/images/product/chose-icon.png')" size="19" class="address-chose-icon" @click="choseAddressHandle"/>
            </template>
          </van-cell>-->
          <van-cell v-if="productDetail.freightDesc && productDetail.freightDesc.length>0" title="运费"  center>
            <template #title>
              <div class="title">
                <div class="chose-title">
                  运费
                </div>
                <div class="chose-content">
                  {{productDetail.freightDesc}}
                </div>
              </div>
            </template>
            <template #right-icon>
              <van-icon :name="require('@/assets/images/product/points.png')" size="19" @click="freightNoteShow=true" />
            </template>
          </van-cell>
          <van-cell v-if="productDetail.guaranteeServiceList && productDetail.guaranteeServiceList.length>0" center >
            <template #title>
              <div class="title">
                <div class="chose-title">
                  服务
                </div>
                <div class="chose-content">
                  <van-row type="flex" gutter="20">
                    <van-col span="12" v-for="(item, index) in productDetail.guaranteeServiceList" :key="index">
                      <div class="guarantee-service-item">
                        <van-icon :name="require('@/assets/images/product/guarantee-service-icon.png')" size="14" />
                        {{item.name}}
                      </div>
                    </van-col>
                  </van-row>
                </div>
              </div>
            </template>
            <template #right-icon>
              <van-icon :name="require('@/assets/images/product/points.png')" size="19" @click="guaranteeServiceShow=true" />
            </template>
          </van-cell>

        </div>

        <div class="block-item detail">
          <div class="title">
            <div class="line"/>
            <span>商品详情</span>
            <div class="line"/>
          </div>
          <div class="mobile-html" v-html="productDetail.detailMobileHtml"/>
        </div>

      </div>

    </van-col>
  </van-row>
  <van-tabbar fixed safe-area-inset-bottom placeholder>
    <div class="bottom-line">
      <van-button text="立即购买" size="small" class="bottom-buy-btn" round color="linear-gradient(93.37deg, #FF2020 15.11%, #FF4E16 93.24%)" block @click="showSkuHandle"/>
      <div class="bottom-app-open" v-if="bottomAppAlertShow">
        <div class="inner">
          <van-icon name="clear" size="15" @click="bottomAppAlertShow = false"/>
          <div class="open-btn" @click="openAppHandle">
            <van-image :src="require('@/assets/images/product/logo-transparent.png')" height="26" width="26"></van-image>
            <span>打开APP</span>
          </div>
        </div>
      </div>
    </div>
  </van-tabbar>
  <open-app ref="openApp" :open-app-uri="openAppUri"/>
  <van-action-sheet v-model="buyActionShow" :closeable="false" safe-area-inset-bottom	>
    <div class="buy-wrap">
      <div class="close">
        <van-button icon="cross" color="rgba(242, 242, 242, 1)" size="mini" round @click="buyActionShow = false"/>
      </div>
      <div class="product">
        <van-image :src="productDetail.pic" width="85" height="85" lazy-load radius="5" />
        <div class="info-wrap">
          <div class="product-price">

            <div class="price">
              <div class="small">￥</div>
              <template v-if="productDetail.price === 0">0.00</template>
              <template v-else>
                {{ formatPrice(productDetail.price)[0] }}<div class="small">{{ '.' + formatPrice(productDetail.price)[1] }}</div>
              </template>
            </div>
            <div class="original-price">
              <div class="original-price-del-line"/>
              <div class="small">￥</div>
              <template v-if="productDetail.originalPrice === 0">0.00</template>
              <template v-else>
                {{ formatPrice(productDetail.originalPrice)[0] }}<div class="small">{{ '.' + formatPrice(productDetail.originalPrice)[1] }}</div>
              </template>
            </div>


          </div>
          <div class="product-sn mt-3" v-if="productDetail.productSn">编号：{{productDetail.productSn}}</div>
        </div>
      </div>
      <div class="step">
        <van-cell title="数量" border title-style="font-size:14px; color:#000000;" center>
          <van-stepper @change="quantityChangeHandle" v-model="productDetail.quantity" />
        </van-cell>
      </div>
      <van-divider/>
      <div class="buy-btn">
        <van-button text="立即购买" size="small" round color="linear-gradient(93.37deg, #FF2020 15.11%, #FF4E16 93.24%)" block @click="onBuyClicked"/>
      </div>
    </div>
  </van-action-sheet>
  <van-action-sheet v-model="guaranteeServiceShow" :closeable="false" safe-area-inset-bottom>
    <div class="guarantee-service-detail-wrap">
      <div class="title">保障服务</div>
      <van-divider/>
      <div class="service-item-wrap">
        <div class="service-item" v-for="(item, index) in productDetail.guaranteeServiceList" :key="index">
          <div class="icon-side">
            <van-icon :name="item.logoUrl" size="13"/>
          </div>
          <div class="content-side">
            <div class="name">{{item.name}}</div>
            <div class="desc">{{item.description}}</div>
          </div>
        </div>
        <div class="btn-wrap">
          <van-button color="linear-gradient(93.37deg, #FF2020 15.11%, #FF4E16 93.24%)" round @click="guaranteeServiceShow=false">确定</van-button>
        </div>

      </div>
    </div>
  </van-action-sheet>

  <van-action-sheet v-model="freightNoteShow" :closeable="false" safe-area-inset-bottom>
    <div class="guarantee-service-detail-wrap">
      <div class="title">运费</div>
      <van-divider/>
      <div class="service-item-wrap">
        <div class="service-item">
          <div class="icon-side">
            <van-icon :name="require('@/assets/images/product/guarantee-service-icon.png')" size="13"/>
          </div>
          <div class="content-side">
            <div class="name">运费说明</div>
            <div class="desc">{{productDetail.freightNote}}</div>
          </div>
        </div>
        <div class="btn-wrap">
          <van-button color="linear-gradient(93.37deg, #FF2020 15.11%, #FF4E16 93.24%)" round @click="freightNoteShow=false">确定</van-button>
        </div>

      </div>
    </div>
  </van-action-sheet>
  <loading-overlay :loading="loading"/>
    <wechat/>
  </div>
</template>

<script>
import Vue from 'vue'
import Wechat  from '@/components/Wechat'
import {
  ActionSheet,
  Area,
  Button,
  Cell,
  CellGroup,
  Col,
  Dialog,
  Icon,
  Image,
  Lazyload,
  Overlay,
  Row,
  Sticky,
  Swipe,
  SwipeItem,
  Tabbar,
  TabbarItem,
  Toast,
  Stepper,
  Divider
} from 'vant'
import {mapGetters} from 'vuex'
import {detail as fetchProductDetail} from '@/api/product'
import OpenApp from "@/components/OpenApp";
import LoadingOverlay from "@/components/LoadingOverlay";

Vue.use(Row)
Vue.use(Col)
Vue.use(Image)
Vue.use(Button)
Vue.use(Toast)
Vue.use(Sticky)
Vue.use(Icon)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Lazyload)
Vue.use(Overlay)
Vue.use(Dialog)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Area)
Vue.use(ActionSheet)
Vue.use(Divider)
Vue.use(Stepper)

const defaultProductDetail = {
  pic:'',
  albumPics:[],
  priceBgUrl:'',
  productSn:'',
  price:'', //销售价格
  sharePrice:'', //H5展示价格
  originalPrice:'', //市场价格
  iconUrl:'', //标题图标
  name:'', //产品名称
  subTitle:'', //副标题
  choseSku: [], //已选择sku
  quantity: 1, //数量
  unit: '', //单位
  freightDesc: '', //运费说明
  freightNote: '', //运费描述
  guaranteeServiceList: [], //商品服务，没有配置返回空列表
  detailMobileHtml:'', //手机详情
}

export default {
  name: "Detail",
  components: {Wechat, OpenApp, LoadingOverlay},
  data(){return{
    //choseAddressActionSheetShow: false,
    loading: false,
    openAppUri: '',
    buyActionShow:false,
    swipeCurrent: 0,
    topAppAlertShow: true,
    bottomAppAlertShow: true,
    productDetail:Object.assign({}, defaultProductDetail),
    guaranteeServiceShow: false,
    freightNoteShow: false,
  }},
  filters:{

  },
  computed: {
    ...mapGetters({
      productId: 'getProductId',
      model: 'getModel',
      invitationCode: 'getInvitationCode',
      skuId: 'getSkuId',
      quantity: 'getQuantity',
    }),
    showSku(){
      //console.log(this.productDetail.choseSku)
      const choseSku = JSON.parse(JSON.stringify(this.productDetail.choseSku))
      if(choseSku){
        choseSku.push(this.productDetail.quantity + this.productDetail.unit)
      }
      return choseSku.join('，')
      // return this.productDetail.choseSku.join('，')

    }
  },
  created() {
    //this.productDetail = Object.assign({}, defaultProductDetail)
    this.$store.dispatch('updateProduct', this.$route.query)
  },
  mounted() {
    // 快速下单模式判断
    if(this.$route.query && this.$route.query.model && this.$route.query.model === 'fast'){
      this.$router.replace({name:'orderConfirm', query: this.$route.query, params: this.$route.params})
    }else {
      this.getProductDetail()
    }
  },
  methods:{
    onBuyClicked(){
      const query = {id: this.productId,
        model: this.model,
        skuId: this.skuId || undefined,
        invitationCode: this.invitationCode,
        quantity: this.quantity
      };
      this.$router.push({name:'orderConfirm', query: query})
    },
    quantityChangeHandle(){
      this.$store.dispatch('updateQuantity', this.productDetail.quantity)
    },
    showSkuHandle(){
      this.buyActionShow = true
    },
    openAppHandle(){
      const machine = navigator.userAgent;
      const isiOS = !!machine.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if(isiOS){
        this.openAppUri = 'employeebenefitpurchasedemo://goods.detail/comgoodsdetail?id=' + this.productId
      }else{
        this.openAppUri = 'zhonghui://goods.detail/comgoodsdetail?id=' + this.productId
      }

      this.$refs.openApp.start();
    },
    choseAddressHandle(){
      this.choseAddressActionSheetShow = true
    },
    swipeChangeHandle(index){
      this.swipeCurrent = index
    },
    formatPrice:function(value){
      /*if(!value){
        return 0.00
      }*/
      if (!value || isNaN(value)) {
        value = 0
      }
      value =Number(value).toFixed(2);
      /*if(value===0){
        return [0.00]
      }
      if(value.split('.')[1].substring(1)==='0'){
        value = Number(value).toFixed(2);
      }*/
      return value.split('.');
    },
    initProductDetailInfo(detail){
      //console.log(detail)
      const productDetail = Object.assign({}, defaultProductDetail)
      const {pic, priceBgUrl, originalPrice, iconUrl, subTitle, name, freightDesc, albumPics, unit, detailMobileHtml, productSn, freightNote, sharePrice} = detail.product
      // price
      productDetail.pic = pic
      productDetail.productSn = productSn
      productDetail.unit = unit || '件'
      productDetail.albumPics = albumPics ? albumPics.split(','): []
      productDetail.priceBgUrl = priceBgUrl || require('@/assets/images/product/price-banner.png')

      productDetail.originalPrice = originalPrice

      //productDetail.price = price
      productDetail.price = sharePrice
      productDetail.freightDesc = freightDesc
      productDetail.freightNote = freightNote
      productDetail.detailMobileHtml = detailMobileHtml
      productDetail.guaranteeServiceList = detail.guaranteeServiceList ? detail.guaranteeServiceList : []

      // 处理sku
      const {skuStockList} = detail
      if(this.skuId && skuStockList && skuStockList.length>0){
        //console.log(this.skuId)
        const activeSku = skuStockList.find(item=>{
          return item.id === this.skuId
        })
        productDetail.price = activeSku ? activeSku.price : productDetail.price
        productDetail.choseSku = activeSku ? JSON.parse(activeSku.spData).map(item=>{return item.value}) : []
        productDetail.productSn = activeSku ? activeSku.skuCode:productSn
      }

      // productDetail.choseSku.push('1件')

      // 标题部分
      productDetail.name = name
      productDetail.subTitle = subTitle
      productDetail.iconUrl = iconUrl

      // this.productDetail = productDetail
      this.productDetail = Object.assign({}, productDetail)


    },
    getProductDetail(){
      this.loading = true
      fetchProductDetail(this.productId).then(
          res=>{
            this.loading = false
            if(res.code === 200){
              this.initProductDetailInfo(res.data)

            }else{
              Toast.fail(res.message||'取商品信息失败');
            }
          },
          err=>{
            this.loading = false
            Toast.fail('取商品信息出错');
            console.log(err)
          }
      )
    }
  }
}
</script>

<style scoped lang="sass">
.top-app-alert
  display: flex
  flex-direction: row
  background-color: #333333
  height: 45px
  justify-content: space-between
  align-items: center
  .left
    color: #ffffff
    display: flex
    font-size: 13px
    flex-direction: row
    align-items: center
    .text
      margin-left: 10px
    .close
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      padding: 10px
      cursor: pointer
    .logo
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      margin-left: 5px
  .open-btn
    cursor: pointer
    display: flex
    justify-content: center
    align-items: center
    height: 25px
    border: 1px solid #FFFFFF
    border-radius: 32px
    box-sizing: border-box
    margin-right: 12px
    color: #ffffff
    font-size: 14px
    padding: 0 10px


.detail
  display: flex
  flex-direction: column

  //background-color: #F4F5F9
  //background-color: #F4F500
  //align-items: center
  width: 100%
  .banner
    display: flex
    width: 100%
    background-color: #fff
    .van-swipe-item
      width: 100vw
    .custom-indicator
      position: absolute
      right: 5px
      bottom: 5px
      padding: 2px 5px
      border-radius: 5px
      font-size: 12px
      background: rgba(125, 125, 125, 0.18)
      color: #FFFFFF
  .price-wrap
    display: flex
    width: 100%
    flex-direction: column
    position: relative
    .price-absolute
      position: absolute
      width: 100%
      //background-color: #0FF
      height: 100%
      top: 0
      left: 0

      .inner
        //background-color: rgba(225,225, 0 ,0.6)
        position: relative
        width: 100%
        height: 100%
        display: flex
        flex-direction: row
        justify-content: flex-start
        align-items: center
        .wrap
          color: #fff
          font-weight: 400
          margin-left: 25px
          margin-right: 25px
          display: flex
          flex-direction: row
          align-items: center
          height: 100%
          .price-line
            display: flex
            flex-direction: row
            align-items: baseline
            .price, .original-price
              display: flex
              flex-direction: row
              align-items: baseline
              //line-height: 1
              //height: 35px

            .price
              font-size: 35px
              margin-right: 10px
              &:first-letter
                font-size: 25px
              .small
                font-size: 25px
            .original-price
              font-size: 19px
              position: relative
              .small
                font-size: 15px
              .original-price-del-line
                position: absolute
                line-height: 0
                width: 100%
                height: 0
                border-bottom: #FFFFFF solid 1px
                top: 50%
  .product-title
    //display: flex
    //flex-direction: row
    color: #333333
    background-color: #FFFFFF
    border-radius: 5px
    font-size: 16px
    padding: 15px
    //line-height: 1
    .van-icon
      margin-right: 3px

  .block-item
    margin-top: 10px
    background-color: #FFFFFF
    border-radius: 5px
    &.service
      display: flex
      flex-direction: column
      padding: 0 15px 7px 15px
      .title
        margin-top: 7px
        display: flex
        flex-direction: column
        justify-content: center
        align-items: center
        font-size: 15px
        font-weight: 400
        color: #333333
      .icon-line-wrap
        display: flex
        flex: 1
        justify-content: space-between
        align-items: center
        margin-left: 45px
        margin-right: 45px
      .text-line-wrap
        display: flex
        justify-content: space-between
        margin: 5px 30px
        .text-item
          font-size: 13px

    &.product-chose
      .address-chose-icon
        line-height: inherit
      .title
        display: flex
        flex-direction: row
        align-items: center
        flex: 1
        .chose-title
          display: flex
          margin-right: 30px
          font-size: 14px
          color: #999999
          width: 45px
        .chose-content
          color: #333333
          font-size: 14px
          display: flex
          flex-direction: column
          flex: 1
          .guarantee-service-item
            display: flex
            flex-direction: row
            align-items: stretch
            .van-icon
              margin-right: 5px
              margin-top: 5px
    &.detail
      .title
        display: flex
        flex-direction: row
        height: 40px
        justify-content: center
        align-items: center
        color: #666666
        font-size: 15px
        background-color: #f4f5f9
        .line
          width: 20px
          height: 0
          line-height: 0
          border-bottom: 1px solid #666666
          margin-left: 8px
          margin-right: 8px
      .mobile-html
        width: 100vw
        display: block
.bottom-line
  display: flex
  flex: 1
  padding: 6px 30px
  justify-content: center
  align-items: center
  position: relative
  .van-button__text
    font-size: 15px
    font-weight: 400
  .bottom-buy-btn
    height: 36px

.bottom-app-open
  position: absolute
  width: 100vw
  top: -50px
  left: 0
  //padding-bottom: 20px
  .inner
    width: 100vw
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center
    //height: 40px
    .van-icon
      color: rgba(0, 0, 0, 0.3)
    .open-btn
      margin-left: 10px
      display: flex
      flex-direction: row
      justify-content: center
      align-items: center
      font-size: 15px
      padding: 7px
      color: rgba(255, 255, 255, 1)
      background: linear-gradient(86.63deg, rgba(239, 66, 36, 0.9) 15.45%, rgba(242, 98, 74, 0.9) 126.65%)
      border-radius: 10px
      cursor: pointer
      span
        margin: 0 10px
.buy-wrap
  padding: 15px 15px 5px 15px
  background-color: #fff
  position: relative
  display: flex
  flex-direction: column
  .close
    position: absolute
    top: 15px
    right: 15px
    .van-icon
      color: rgba(153, 153, 153, 1)


  .product
    display: flex
    flex-direction: row
    align-items: center
    flex: 1
    .info-wrap
      display: flex
      flex-direction: column
      align-items: center
      justify-content: flex-start
      flex: 1
      margin-left: 10px
      .product-price
        display: flex
        width: 100%
        flex-direction: row
        align-items: baseline
        .price
          display: flex
          flex-direction: row
          color: #ff0000
          font-size: 24px
          font-weight: 700
          align-items: baseline
          .small
            font-size: 18px
        .original-price
          display: flex
          flex-direction: row
          position: relative
          font-size: 15px
          font-weight: 400
          color: #999999
          margin-left: 9px
          align-items: baseline
          .small
            font-size: 12px
          .original-price-del-line
            position: absolute
            line-height: 0
            width: 100%
            height: 0
            border-bottom: #999999 solid 1px
            top: 50%

      .product-sn
        display: flex
        width: 100%
        color: #999999
        font-size: 12px

  .buy-btn
    margin: 0 30px
    .van-button
      font-size: 15px
      height: 36px

.guarantee-service-detail-wrap
  width: 100%
  display: flex
  flex-direction: column
  .title
    display: flex
    width: 100%
    justify-content: center
    margin-top: 12px
    font-size: 16px
    color: #333333
  .service-item-wrap
    display: flex
    flex-direction: column
    flex: 1
    padding: 0 16px
    .service-item
      display: flex
      flex-direction: row
      width: 100%
      justify-content: flex-start
      align-items: flex-start
      margin-bottom: 16px
      .icon-side
        margin-right: 10px
        display: flex
        align-items: center
        height: 20px
      .content-side
        display: flex
        flex-direction: column
        .name
          font-size: 14px
          color: #333333
          display: flex
          align-items: center
          height: 20px
        .desc
          margin-top: 7px
          font-size: 12px
          color: #666666

    .btn-wrap
      display: flex
      flex-direction: column
      width: 100%
      margin-bottom: 6px
      .van-button
        height: 36px
        border-radius: 36px

</style>

<style lang="sass">
.mobile-html
  line-height: 0
  img
    max-width: 100vw
    width: 100%!important
</style>
