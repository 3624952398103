<template>
<div>
  <van-overlay :show="openAppOverlayShow" @click="openAppOverlayShow = false" >
    <div class="open-app-overlay-wrap">
      <van-image :src="require('@/assets/images/common/open-in-browser.jpg')" width="100vw" height="100vh"/>
    </div>
  </van-overlay>

  <van-dialog v-model="openAppDialogShow" :show-confirm-button="false" >
    <div class="open-app-dialog-wrap">
      <van-image :src="require('@/assets/images/product/logo-sm.png')" width="55" height="55"/>
      <span class="mt-8">此功能需访问众惠其珹APP</span>
      <span class="mt-2">正在为您打开...</span>

    </div>
  </van-dialog>
  <van-dialog v-model="downDialogShow" :show-confirm-button="false">
    <div class="download">
      <div class="content">此功能需访问众惠其珹APP</div>
      <div class="btn-wrap">
        <div class="btn">
          <van-button block size="small" color="#FF2020" plain round @click="downDialogShow=false">关闭</van-button>
        </div>
        <div class="btn">
          <van-button block size="small" color="#FF2020" round @click="downloadAppHandle">下载APP</van-button>
        </div>
      </div>
    </div>

  </van-dialog>

</div>
</template>

<script>
import Vue from "vue";
import {Dialog, Overlay, Image, Button} from "vant";
import {isInWeixin} from "@/utils/helper";

Vue.use(Overlay)
Vue.use(Dialog)
Vue.use(Image)
Vue.use(Button)
export default {
  name: "OpenApp",
  props:{
    openAppUri:{
      type: String,
      default: ''
    },
    downloadAppUrl:{
      type: String,
      default: 'http://portal.zhqcmall.com/appdownload.html'
    },
  },
  data(){return{
    openAppOverlayShow: false,
    openAppDialogShow: false,
    downDialogShow: false,
  }},
  methods:{
    downloadAppHandle(){
      window.location.href = this.downloadAppUrl;
    },
    start(){
      setTimeout(()=>{
        this.openAppHandle()
      }, 10)
    },
    openAppHandle(){
      if(isInWeixin()){
        this.openAppOverlayShow = true
      }else{
        this.openAppDialogShow = true
        let t = 1000
        let hasApp = true

        // N秒后判断是否安装了APP
        setTimeout(()=> {
          this.openAppDialogShow = false

          if (!hasApp){  // 未安装APP
            this.downDialogShow = true

          }else  // 已安装APP
            Dialog.alert({
              message: 'APP打不开？请尝试浏览器打开此页。',
              theme: 'round-button',
            }).then(() => {
              // on close
            });
        }, 2000);

        // 创建iframe打开APP的uri

        window.location.href = this.openAppUri;
        // 如果还执行定时器，表明未离开当前页，即没有打开APP
        const t1 = Date.now();
        setTimeout(()=> {
          const t2 = Date.now();
          if (!t1 || t2 - t1 < t + 100)
            hasApp = false;
        }, t);
      }
    },
  }
}
</script>

<style scoped lang="sass">
.open-app-overlay-wrap
  display: flex
  align-items: center
  justify-content: center
  height: 100%
.open-app-dialog-wrap
  padding: 25px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  color: #333333
  font-size: 13px

.download
  display: flex
  flex-direction: column
  margin: 30px
  flex: 1
  .content
    display: flex
    justify-content: center
    align-items: center
    color: #333333
    font-size: 13px
  .btn-wrap
    display: flex
    justify-content: space-between
    align-items: center
    flex: 1
    margin-top: 30px
    .btn
      width: 50%
      padding: 5px
</style>
